import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div className="title is-2">NWR Tech</div>
    <div>
      <p>
        NWR Tech creates custom websites for small and medium sized businesses.
        We focus on customers who need a web presence, but don't have the need
        for an internal IT team.
      </p>
      <br />
      <p>
        Let us create a site for you that will properly represent your brand and
        fit your budget. You give us the "goal" and "gaurdrails" and we will
        hanlde all of the details - including purchasing a domain name, building
        the website, hosting the website, and much more.
      </p>
      <br />
      <p>
        <Link>Contact us&nbsp;</Link>
        to get started. We will gather some information from you and build
        a working prototype - at no cost to you.
      </p>
    </div>

    <br />
    <br />
    <hr />

    <div className="title is-2">What We Offer</div>

    <div className="columns is-multiline">
      <div className="column is-third">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Right Price</p>
          </header>
          <div class="card-content">
            <div class="content">
              <p>Complete packages starting at</p>
              <ul>
                <li>$250 one-time setup fee</li>
                <li>$30 per month for hosting</li>
              </ul>
            </div>
          </div>
          <footer class="card-footer">
            <Link to="/pricing" className="card-footer-item">More Details</Link>
          </footer>
        </div>
      </div>

      <div className="column is-third">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Right Size</p>
          </header>
          <div class="card-content">
            <div class="content">
              <p>Appropriately sized website</p>
              <ul>
                <li>pay for what you need</li>
                <li>add features as you grow</li>
              </ul>
            </div>
          </div>
          <footer class="card-footer">
            <Link to="/pricing" className="card-footer-item">More Details</Link>
          </footer>
        </div>
      </div>

      <div className="column is-third">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Done Right</p>
          </header>
          <div class="card-content">
            <div class="content">
              <p>Quailty built in</p>
              <ul>
                <li>fast</li>
                <li>mobile friendly</li>
                <li>secure</li>
              </ul>
            </div>
          </div>
          <footer class="card-footer">
            <Link to="/pricing" className="card-footer-item">More Details</Link>
          </footer>
        </div>
      </div>
    </div>

    <br />
    <br />
    <hr />

    {/* <div className="box has-text-centered">
      <div className="title is-4">
        <p>Benefits of a Website</p>
      </div>
    </div> */}

    <div className="title is-2">Benefits of a Website</div>
    <p>
      Perhaps more important than the benefits of a great website is the cost of
      a poor one.
    </p>
    <br />
    <p>
      In today's world, it is expected that every business has a website. The
      site needs to be fast, user friendly, and mobile friendly.
    </p>
    <br />
    <p>
      Not having a website or having a poorly designed webstie is costing you
      potential customers. Don't take our word, do the research!
    </p>
    <br />
    <br />
    <article className="message">
      <div className="message-body">
        <div className="is-size-4">
          <p>Let's build your website</p>
        </div>
        <div className="content">
          <ul>
            <li>Build credibility for your business</li>
            <li>Cost effective way to communicate with your customers</li>
            <li>Advertise your products and services</li>
            <li>
              Share your contact information and address with local customers
            </li>
          </ul>
        </div>
      </div>
    </article>

    {/* 
    <br /><br />
    <hr />

    <article className="message">
      <div className="message-body">
        <p>What does NWR stand for?</p>
        <p>
          It's based on the Latin proverb: If there is&nbsp;
          <span className="is-size-4">
            <strong>N</strong>
          </span>
          o
          <span className="is-size-4">
            <strong>W</strong>
          </span>
          ind,
          <span className="is-size-4">
            <strong>R</strong>
          </span>
          ow
        </p>
        <p>
          In other words, we make our own destinty. No wait for your website to
          just happen, contact us today! Let us make it happen.
        </p>
      </div>
    </article> */}
  </Layout>
)

export default IndexPage


// Home
// 	Name
// 	Main blurb
// 	Header Image

// About
// 	Main blurb

// Contact Us
// 	Phone
// 	Email
// 	Address
// 	facebook url

// Hours
// 	Monday
// 	Tuesday
// 	Wednesday
// 	Thursday
// 	Friday
// 	Saturday
// 	Sunday
// 	Holidays
// 	Misc

// FAQ

// Testimonnials
